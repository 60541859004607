<template>
  <v-switch
    v-model="dataRefresh"
    :hint="labels.sync"
    :loading="loading"
    color="white"
    dark
    dense
    inset
    persistent-hint
    hide-details="auto"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'refreshButton',
  computed: {
    ...mapState('shared', [
      'loading',
      'refresh',
    ]),
    dataRefresh: {
      get() {
        return this.refresh;
      },
      set(value) {
        this.toggleRefresh(value);
      },
    },
  },
  data: () => ({
    labels,
  }),
  methods: {
    ...mapActions({
      toggleRefresh: 'shared/toggleRefresh',
    }),
  },
};
</script>

<style scoped>
</style>
